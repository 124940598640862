import React from 'react';
import {
  useNavigation,
  TopNavigation as TopNav,
  Logo,
  Divider,
  ProductTitle,
  GlobalNavigationLink,
  TitleBar,
  PageTitle
} from '@cvent/carina/components/Navigation';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import { OnWhen } from '@cvent/carina/components/OnWhen';

import NextLink from 'next/link';

export function TopNavigation(): JSX.Element {
  const { logo, title, globalNav } = useNavigation();

  return (
    <>
      <TopNav>
        <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: '70%'
            }}
          >
            <Logo
              logo={logo}
              // eslint-disable-next-line react/no-unstable-nested-components
              Link={({ item, children }): JSX.Element => (
                <NextLink href={item.url} passHref>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>{children}</a>
                </NextLink>
              )}
            />
            <OnWhen m l xl>
              <Divider />
              <ProductTitle title={title} />
            </OnWhen>
          </div>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <OnWhen l xl>
              <ul
                role="menu"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                {globalNav.map(item => (
                  <GlobalNavigationLink key={item.title}>
                    <NextLink href={item.url.href} passHref>
                      <a href={item.url.href}>{item.title}</a>
                    </NextLink>
                  </GlobalNavigationLink>
                ))}
              </ul>
            </OnWhen>
          </div>
        </div>
      </TopNav>
      <TitleBar>
        <Row>
          <Col
            width="fill"
            flex={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <PageTitle title={title} />
          </Col>
        </Row>
      </TitleBar>
    </>
  );
}
